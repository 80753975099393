import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import get from "lodash.get";

import serviceBonarka from "../../assets/bonarekService.jpg";
import serviceLegnicka from "../../assets/legnickaSerwis.jpg";
import mapPreview from "../../assets/map.png";

import { Button } from "../../components/buttons/Button";
import { DepositData, FormData } from "../../types";
import { BtnArrowRight } from "../../assets/icons";
import { SmallSummaryCard } from "../../components/cards/SmallSummaryCard";
import { ServiceCard } from "../../components/cards/ServiceCard";
import { CarInfo } from "./components/CarInfo";
import { Deposit } from "./components/Deposit";
import { fetchDepositPlates } from "../../services";
import { getIsValidDepositPlates } from "../../utils";

interface ServicesStepProps {
  setStep: () => void;
  setStepFormData: (key: string, val: any) => void;
  formData: FormData;
}

export const ServicesStep = ({
  setStep,
  setStepFormData,
  formData,
}: ServicesStepProps) => {
  const { service, vehiclePlates, vehicleType, selectedDeposit, vehicleTypeName } =
    formData;

  const [serviceError, setServiceError] = useState(false);
  const [vehicleTypeError, setVehicleTypeError] = useState(false);
  const [vehiclePlatesError, setVehiclePlatesError] = useState(false);
  const [depositError, setDepositError] = useState(false);

  const isValidStep = ![
    service === undefined,
    vehicleType === null,
    !getIsValidDepositPlates(selectedDeposit),
    vehiclePlates.length < 6,
  ].includes(true);

  const nextStep = () => {
    if (isValidStep) {
      setStep();
    } else {
      if (service === undefined) {
        setServiceError(true);
      } else {
        setServiceError(false);
      }
      if (vehicleType === null) {
        setVehicleTypeError(true);
      } else {
        setVehicleTypeError(false);
      }
      if (!getIsValidDepositPlates(selectedDeposit)) {
        setDepositError(true);
      } else {
        setDepositError(false);
      }
      if (vehiclePlates.length < 6) {
        setVehiclePlatesError(true);
      } else {
        setVehiclePlatesError(false);
      }
    }
  };

  const setService = (serviceId: number) => {
    setServiceError(false);
    setVehiclePlatesError(false);
    setVehicleTypeError(false);
    setStepFormData("service", serviceId);
    setStepFormData("selectedDate", null);
  };

  const {
    data: depositsPlatesData,
    refetch: depositsPlatesDataRefetch,
    ...depositsPlatesDataOther
  } = useQuery<{
    data: { Depozyty: DepositData[] } | null;
  }>({
    queryKey: ["depositsPlates"],
    queryFn: () => fetchDepositPlates(vehiclePlates),
    enabled: false,
  });

  return (
    <div className="px-4 lg:max-w-[1440px] lg:mx-auto">
      <div className="text-3xl font-bold text-blueMain my-5">Wybierz serwis</div>
      <div className="lg:flex gap-5 justify-between">
        <div className="lg:max-w-[800px] w-full">
          <div className="w-full grid grid-rows-base-rows md:grid-rows-md-rows md:grid-cols-md-cols lg:grid-cols-1 lg:grid-rows-lg-rows gap-3">
            <ServiceCard
              img={serviceBonarka}
              checked={service === 6}
              onClick={() => {
                if (service !== 6) {
                  setService(6);
                }
              }}
              isError={serviceError}
              serviceName="Serwis Bonarka (-1 parking)"
              address="ul. Kamieńskiego 11, Kraków"
              map={mapPreview}
              openingHours={{
                normal: 'Pn. - Pt.: 9:00 - 21:00',
                weekend: 'Sb.: 9:00 - 18:00'
              }}
            />
            <ServiceCard
              img={serviceLegnicka}
              checked={service === 5}
              onClick={() => {
                if (service !== 5) {
                  setService(5);
                }
              }}
              isError={serviceError}
              serviceName="Serwis Legnicka"
              address="ul. Legnicka 5, Kraków"
              openingHours={{
                normal: 'Pn. - Pt.: 8:00 - 18:00',
                weekend: 'Sb.: 8:00 - 14:00'
              }}
            />
            {serviceError && (
              <div className="text-sm text-redMain">
                Musisz wybrać serwis, aby przejść dalej
              </div>
            )}
          </div>
          {service && (
            <CarInfo
              service={service}
              vehiclePlates={vehiclePlates}
              vehiclePlatesError={vehiclePlatesError}
              vehicleType={vehicleType}
              vehicleTypeError={vehicleTypeError}
              vehicleTypeName={vehicleTypeName}
              setVehicleTypeError={(val) => setVehicleTypeError(val)}
              setVehiclePlatesError={(val) => setVehiclePlatesError(val)}
              setStepFormData={(key, val) => {
                setStepFormData(key, val);
              }}
              depositsPlatesRefetch={() => depositsPlatesDataRefetch()}
            />
          )}

          {service &&
            (depositsPlatesData !== undefined || depositsPlatesDataOther.isFetching) && (
              <Deposit
                isFetching={depositsPlatesDataOther.isFetching}
                depositsPlatesData={
                  depositsPlatesData !== undefined
                    ? get(depositsPlatesData, "data.Depozyty", null)
                    : undefined
                }
                setDepositError={(val) => setDepositError(val)}
                depositError={depositError}
                vehiclePlates={vehiclePlates}
                selectedDeposit={selectedDeposit}
                setStepFormData={(key, val) => {
                  setStepFormData(key, val);
                }}
              />
            )}

          <div className="pb-10">
            <Button onClick={nextStep} btnClassName="mt-6">
              Przejdź do terminów
              <BtnArrowRight stroke={"#FFF"} />
            </Button>
          </div>
        </div>
        {service && (
          <div className="hidden lg:block">
            <SmallSummaryCard
              formData={{
                service,
                vehiclePlates,
                vehicleType,
                vehicleTypeName,
                selectedDeposit,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
